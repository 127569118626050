/* eslint-disable complexity */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
import type { NextPage } from 'next'
import Image from 'next/image'
import Head from 'next/head'
import React, { memo, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useAppSelector, useAppDispatch } from 'src/app/hooks'
import { useRouter } from 'next/router'
import styles from '../assets/styles/04_page/index.module.scss'
import Background from 'src/components/Background/Background'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  getCountries,
  getPortfolios,
  getProjects,
  getSDGs,
  getTypesCategories
} from 'src/features/projects/projectsAPI'
import { setPortfolios, setProject } from 'src/features/projects/projectSlice'
import {
  getProjectsTags,
  getSocialProof
} from 'src/features/compensation/compensationAPI'
import {
  setCompensationTypes,
  setCurrencySymbol,
  setDifferentCategories,
  setEnergyTypes,
  setImpacts,
  setMaxPrice,
  setMaxStock,
  setMechanism,
  setProjectTags,
  setRegistryTypes,
  setStandardTypes
} from 'src/features/compensation/compensationSlice'
import { Button, Col, Row, Select, message } from 'antd'
import Search from 'src/components/Search/Search'
import UploadProject from 'src/components/UploadProject/UploadProject'
import Filters from 'src/components/Filters/Filters'
import CategoriesPreFilter from 'src/components/CategoriesPreFilter/CategoriesPreFilter'
import {
  setFilteredProjects,
  setOrder,
  setPrice,
  setStock,
  setYearSince,
  setYearUntil,
  setRefreshFilters,
  setQueryWrite,
  setQueryFilters
} from 'src/features/filters/filtersSlice'
import {
  capitalizeFirstLetter,
  getExpensivePrice,
  getHigherStock,
  getNewestVintage,
  getOlderVintage,
  queryFilterReader,
  shuffle
} from 'src/helpers/utils'
import { ls_currencycode_name } from 'src/static/models/localstorage.names'
import SkeletonCategoryProjects from 'src/components/SkeletonCategoryProjects/SkeletonCategoryProjects'
import SkeletonPreFiltersMobile from 'src/components/SkeletonPreFiltersMobile/SkeletonPreFiltersMobile'
import SkeletonFilters from 'src/components/SkeletonFilters/SkeletonFilters'
import CardProjects from 'src/components/CardProjects/CardProjects'
import ModalFilter from 'src/components/Filters/ModalFilters/ModalFilters'
import { checkCookies, trackEvent } from 'src/features/visitors/actions'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import menuOutlined from '../assets/img/menuOutlined.png'
import plant from '../assets/img/plant.png'
import CardDidYouKnowThat from 'src/components/CardDidYouKnowThat/CardDidYouKnowThat'
import ExitIntentPopup from 'src/components/ExitIntentPopup/ExitIntentPopup'
import WhatsNewBanner from 'src/components/WhatsNewBanner/WhatsNewBanner'
import { setReferralCodeCookie } from 'src/helpers/cookies.helpers'
import PopularFilters from 'src/components/Filters/PopularFilters/PopularFilters'
import { Portfolio } from 'src/interfaces/InterfaceGlobal'
import CardPortfolio from 'src/components/CardProjects/CardPortfolio'

const didYouKnowCardsQuantityToShow = 5
const didYouKnowCardsQuantity = 9

const HomePage: NextPage = () => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const projects = useAppSelector((state) => state.project.project)
  const currencies = useAppSelector((state) => state.compensation.currencies)
  const currencySymbol = useAppSelector((state) => state.compensation.currencySymbol)
  const projectTags = useAppSelector(
    (state) => state.compensation.projectTags
  )
  const mechanisms = useAppSelector((state) => state.compensation.mechanism)
  const price = useAppSelector((state) => state.filters.price)
  const stock = useAppSelector((state) => state.filters.stock)
  const yearSince = useAppSelector((state) => state.filters.yearSince)
  const yearUntil = useAppSelector((state) => state.filters.yearUntil)
  const search = useAppSelector((state) => state.filters.search)
  const user = useAppSelector((state) => state.user)
  const loading = useAppSelector((state) => state.ui.loading)
  const isUserLogued = useAppSelector((state) => state.user.isUserLogued)
  const order = useAppSelector((state) => state.filters.order)
  const reloadProjects = useAppSelector(
    (state) => state.project.reloadProject
  )
  const currencyCodeEnv = process.env.CURRENCY_CODE
  const filteredProjects = useAppSelector(
    (state) => state.filters?.filteredProjects
  )
  const refreshFilters = useAppSelector(
    (state) => state.filters.refreshFilters
  )
  const name = useAppSelector((state) => state.filters.name)
  const registry = useAppSelector((state) => state.filters.registry)
  const kindFilter = useAppSelector((state) => state.filters.projectKind)
  const countries = useAppSelector((state) => state.filters.countries)
  const mechanismSelected = useAppSelector(
    (state) => state.filters.selectedMechanism
  )
  const SDGsRedux = useAppSelector((state) => state.filters.SDGs)
  const listOfCategories = useAppSelector(
    (state) => state.compensation.differentCategories
  )
  const selectedTypes = useAppSelector((state) => state.filters.selectedTypes)

  const selectedStandardType = useAppSelector(
    (state) => state.filters.selectedStandardType
  )
  const selectedEnergyType = useAppSelector(
    (state) => state.filters.selectedEnergyType
  )
  const selectedCompensationType = useAppSelector(
    (state) => state.filters.selectedCompensationType
  )
  const selectedProjectTags = useAppSelector(
    (state) => state.filters.selectedProjectTags
  )
  const selectedImpacts = useAppSelector(
    (state) => state.filters.selectedImpacts
  )
  const withoutStock = useAppSelector((state) => state.filters.withoutStock)
  const minimumPurchase = useAppSelector(
    (state) => state.filters.minimumPurchase
  )
  const currencyCode = useAppSelector(
    (state) => state.compensation.currency_code
  )
  const filtersLoaded = useAppSelector((state) => state.filters.sessionLoaded)
  const queryWrite = useAppSelector((state) => state.filters.queryWrite)
  const queryRead = useAppSelector((state) => state.filters.queryRead)

  // for infinite scrolling
  const [projectList, setProjectList] = useState<any[]>([])
  const [portfolioList, setPortfoliotList] = useState<any[]>([])
  const [mobileProjectList, setMobileProjectList] = useState<any[]>([])
  const [showMore, setShowMore] = useState<boolean>(false)
  const [inMobile, setInMobile] = useState<boolean>(false)
  const [page, setPage] = useState(0)
  const [totalProjects, setTotalProjects] = useState<number>(1)
  const [remainingCards, setRemainingCards] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)
  const [firstTime, setFirstTime] = useState<boolean>(true)
  const [showTabletFilter, setShowTabletFilter] = useState<boolean>(false)
  const [didYouKnowCards, setDidYouKnowCards] = useState<number[]>([])
  const [stopButtonApplyFilter, setStopButtonApplyFilter] = useState<boolean>(
    false
  )
  const buttonApplyFilterRef = useRef(null)
  const filterColumnRef = useRef<HTMLDivElement>(null)

  const openNotification = (description: string) => {
    message.success({
      content: (
        <Row>
          <Col span={1}>
          </Col>
          <Col span={20}>
            <p className={styles['description']}>{description}</p>
          </Col>
        </Row>
      ),
      className: styles['notification'],
      duration: 6,
      icon: (
        <Row>
          <Col span={1}>
            <Image src={plant} alt='Plant' />
          </Col>
          <Col span={20}>
            <h5 className={styles['notificationTitle']}>
              {t('notification.title')}
            </h5>
          </Col>
          <Col span={1}>
          </Col>
        </Row>
      )
    })
  }

  const queryFilters = async () => {
    if (queryWrite) {
      applyFilters()
      dispatch(setQueryWrite(false))
      return
    }
    const query = router.query

    const newQueryFilters = {
      search: query.search ? query.search[0] : '',
      countries: [],
      selectedProjectTags: [],
      selectedTypes: [],
      SDGs: [],
      projectKind: [],
      selectedMechanism: []
    }

    // query param for category

    if (query.category) {
      let projectKindQuery = []
      if (Array.isArray(query?.category)) {
        query.category?.map((kind) =>
          projectKindQuery.push(
            capitalizeFirstLetter(kind.toLowerCase())
          )
        )
      } else {
        projectKindQuery.push(
          capitalizeFirstLetter(query.category?.toLowerCase())
        )
      }
      newQueryFilters.projectKind = projectKindQuery
    }

    if (query.characteristic) {
      newQueryFilters.selectedProjectTags = queryFilterReader(
        'characteristic',
        projectTags,
        'name',
        'name_en',
        'id'
      )
      dispatch(setFiltersKeys(['characteristics']))
    }

    if (query.country) {
      try {
        const res = await getCountries()
        newQueryFilters.countries = queryFilterReader(
          'country',
          res,
          'code2',
          null,
          null
        )
        dispatch(setFiltersKeys(['characteristics']))
      } catch (e) {
        console.error(e)
      }
    }

    // query param for types
    if (query.type) {
      let typesList = []
      listOfCategories.forEach((categories) => {
        typesList = typesList.concat(categories.options)
      })
      newQueryFilters.selectedTypes = queryFilterReader(
        'type',
        typesList,
        'value',
        null,
        null
      )
      dispatch(setFiltersKeys(['projectType']))
    }

    // query param for mechanisms
    if (query.mechanism) {
      const avoidance = [
        'Avoidance',
        'Emisones Evitadas',
        'Évitement',
        '기피',
        'Evitação',
        'Vermeidung',
        'Evitare'
      ]
      const removal = [
        'Removal',
        'Remoción',
        'Suppression',
        '제거',
        'Remoção',
        'Entfernung',
        'Rimozione'
      ]
      const qMechanisms = [query.mechanism].flat()
      newQueryFilters.selectedMechanism = mechanisms.filter((m) =>
        qMechanisms.find((qM) =>
          [avoidance.includes(m) ? avoidance : removal]
            .flat()
            .includes(qM)
        )
      )
      dispatch(setFiltersKeys(['mechanism']))
    }

    // query param for SDG
    if (query.sdg) {
      try {
        const res = await getSDGs()
        newQueryFilters.SDGs = queryFilterReader(
          'sdg',
          res.results,
          'name',
          null,
          'id'
        )

        dispatch(setFiltersKeys(['SDG']))
      } catch (e) {
        console.error(e)
      }
    }
    dispatch(setQueryFilters(newQueryFilters))
  }

  useEffect(() => {
    projects && applyFilters()
  }, [queryRead, projects, refreshFilters])

  useEffect(() => {
    queryFilters()

    window.addEventListener('popstate', queryFilters)
    return () => {
      window.removeEventListener('popstate', queryFilters)
    }
  }, [projects])

  useEffect(() => {
    const loadingFilters =
      sessionStorage.getItem('filters') &&
      sessionStorage.getItem('filters') != 'null' &&
      !filtersLoaded

    const saveOrder = loadingFilters
      ? JSON.parse(sessionStorage.getItem('filters')).order
      : order

    if (loading) {
      let selectedCurrencyCode = currencyCodeEnv
      if (isUserLogued) {
        if (user.user.currency_token_code) {
          selectedCurrencyCode = user.user.currency_token_code
        }
      } else {
        const currencyCodeStorage = localStorage.getItem(
          ls_currencycode_name
        )
        if (currencyCodeStorage) {
          selectedCurrencyCode = currencyCodeStorage
        }
      }

      const currencyUser = currencies.find(
        (el) => el.code === selectedCurrencyCode
      )
      dispatch(setCurrencySymbol(currencyUser?.symbol))

      if (router.query.profile) {
        const exist = Cookies.get('ct-profile')
        if (exist) {
          Cookies.remove('ct-profile')
        }
        Cookies.set('ct-profile', router.query.profile as string, {
          expires: new Date(Date.now() + 2700000)
        })
      }

      const profileCookie = Cookies.get('ct-profile')
      getProjects(saveOrder, profileCookie).then((project) => {
        dispatch(setProject(project))
        getFilteringOptions(project.results)

        dispatch(setMaxPrice(getExpensivePrice(project.results).price))
        dispatch(setMaxStock(getHigherStock(project.results)))
        if (price[1] == null && !loadingFilters) {
          dispatch(
            setPrice([
              price[0],
              getExpensivePrice(project.results).price
            ])
          )
        }
        if (stock[1] == null && !loadingFilters) {
          dispatch(
            setStock([stock[0], getHigherStock(project.results)])
          )
        }
        if (yearSince == null && !loadingFilters) {
          dispatch(setYearSince(getOlderVintage(project.results)))
        }
        if (yearUntil == null && !loadingFilters) {
          const currentYear = new Date().getFullYear()
          const newestVintageYear = getNewestVintage(project.results)
          dispatch(
            setYearUntil(
              currentYear > newestVintageYear
                ? currentYear
                : newestVintageYear
            )
          )
        }
      })

      getTypesCategories()
        .then((res) => {
          let data = res.data
          const validTypeNames = ['type', 'energy_source_type']
          if (data && Array.isArray(data[0].options)) {
            const uniqueValues = {}
            for (const cat of data) {
              cat.options = cat.options.filter((option) => {
                if (
                  !uniqueValues[option.value] &&
                  validTypeNames.includes(option.name)
                ) {
                  uniqueValues[option.value] = true
                  return true
                }
              })
            }
          }
          dispatch(setDifferentCategories(data))
        })
        .catch((e) => console.error(e))

      if (localStorage.getItem('trakingSearch')) {
        dispatch(checkCookies())
        dispatch(
          trackEvent(
            'MARKET_HOME',
            'ACTION_CLICK',
            'HEADER_SEARCH_BOX',
            localStorage.getItem('trakingSearch')
          )
        )
        localStorage.removeItem('trakingSearch')
      }
      getPortfolios().then(portfolios => {
        dispatch(setPortfolios(portfolios))
        setPortfoliotList(portfolios)
      })
      getProjectsTags()
        .then((projectTags) => dispatch(setProjectTags(projectTags)))
        .catch((e) => console.error(e))

      if (firstTime) {
        getSocialProof()
          .then((res) => {
            const randomPos = Math.floor(Math.random() * 5)
            setTimeout(() => {
              openNotification(res[randomPos])
            }, 15000)
            setFirstTime(false)
          })
          .catch((e) => console.error(e))
      }
    }
  }, [loading, currencyCode])
  // filtersLoaded carga doble

  useEffect(() => {
    const { referral_code } = router.query
    if (referral_code) setReferralCodeCookie(referral_code)

    storePathValues(router.asPath)
  }, [router.asPath, router.query])

  const includesSDG = (data: string, project: any) => {
    let include = false
    project.sdg_compliances.forEach((el) => {
      if (el.name.toLowerCase().includes(data)) {
        include = true
      }
    })
    return include
  }

  const includesDescription = (data: string, project: any) => {
    let include = false
    project.descriptions.forEach((el) => {
      if (el.text.toLowerCase().includes(data)) {
        include = true
      }
    })
    return include
  }

  const includesTags = (data: string, project: any) => {
    let include = false
    project.tags.forEach((el) => {
      if (el?.name?.toLowerCase().includes(data)) {
        include = true
      }
    })
    return include
  }

  const includesRegistry = (data: string, project: any) => {
    let include = false
    project.tags.forEach((el) => {
      if (el?.name?.toLowerCase().includes(data)) {
        include = true
      }
    })
    return include
  }

  const getMechanism = (arrayOfProjects: Array<any>) => {
    let arrayOfMechanism = []
    const projectArray = arrayOfProjects?.map(
      (project: any) => project.mechanism
    )
    projectArray?.forEach((mechanism: string) => {
      if (
        !arrayOfMechanism.includes(mechanism) &&
        mechanism !== null &&
        mechanism !== ''
      ) {
        arrayOfMechanism.push(mechanism)
      }
    })
    dispatch(setMechanism(arrayOfMechanism.sort()))
  }

  const getImpacts = (arrayOfProjects: Array<any>) => {
    const projectArray = arrayOfProjects?.map(
      (project: any) => project.impacts
    )
    const cleanedProjectArray = projectArray.filter((imp) => imp.length > 0)
    const flattedArray = cleanedProjectArray.map((imp) => {
      const ar = imp.map((el) => el.name)
      return ar
    })
    const flattened = flattedArray.flatMap((name) => name)
    const removeDuplicatesArray = flattened.filter(
      (item, index) => flattened.indexOf(item) === index
    )
    dispatch(setImpacts(removeDuplicatesArray.sort()))
  }

  const orderArray = (newArray) => {
    if (newArray) {
      if (order === 'name') {
        return newArray.sort((a, b) => a.name.localeCompare(b.name))
      } else if (order === '-name') {
        return newArray.sort((a, b) => b.name.localeCompare(a.name))
      } else if (order === '-scoring') {
        const sortedArray = [...newArray].sort(
          (a, b) => b.scoring - a.scoring
        )
        return sortedArray
      } else if (order === 'price') {
        return newArray.sort((a, b) => {
          if (a.is_vintage) {
            if (b.is_vintage) {
              return (
                a.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                a.vintage_items[0]?.final_unit_price.fee_amount -
                (b.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                  b.vintage_items[0]?.final_unit_price
                    .fee_amount)
              )
            } else {
              return (
                a.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                a.vintage_items[0]?.final_unit_price.fee_amount -
                (b.final_unit_price.sell_base_amount +
                  b.final_unit_price.fee_amount)
              )
            }
          } else {
            if (b.is_vintage) {
              return (
                a.final_unit_price.sell_base_amount +
                a.final_unit_price.fee_amount -
                (b.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                  b.vintage_items[0]?.final_unit_price
                    .fee_amount)
              )
            } else {
              return (
                a.final_unit_price.sell_base_amount +
                a.final_unit_price.fee_amount -
                (b.final_unit_price.sell_base_amount +
                  b.final_unit_price.fee_amount)
              )
            }
          }
        })
      } else if (order === '-price') {
        return newArray.sort((a, b) => {
          if (a.is_vintage) {
            if (b.is_vintage) {
              return (
                b.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                b.vintage_items[0]?.final_unit_price.fee_amount -
                (a.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                  a.vintage_items[0]?.final_unit_price
                    .fee_amount)
              )
            } else {
              return (
                b.final_unit_price.sell_base_amount +
                b.final_unit_price.fee_amount -
                (a.vintage_items[0]?.final_unit_price
                  .sell_base_amount +
                  a.vintage_items[0]?.final_unit_price
                    .fee_amount)
              )
            }
          } else {
            if (b.is_vintage) {
              return (
                b.vintage_items[0].final_unit_price
                  .sell_base_amount +
                b.vintage_items[0].final_unit_price.fee_amount -
                (a.final_unit_price.sell_base_amount +
                  a.final_unit_price.fee_amount)
              )
            } else {
              return (
                b.final_unit_price.sell_base_amount +
                b.final_unit_price.fee_amount -
                (a.final_unit_price.sell_base_amount +
                  a.final_unit_price.fee_amount)
              )
            }
          }
        })
      } else {
        return newArray
      }
    }
  }

  const applyFilters = (orderedArray?: Array<any>) => {
    let newArray = []
    if (orderedArray) {
      newArray = orderedArray
    } else {
      newArray = projects?.results
    }

    if (search) {
      newArray = newArray?.filter((project) => {
        if (
          project.name.toLowerCase().includes(search.toLowerCase()) ||
          project.registry_depositing_name
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.sub_type
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.standard_energy_type
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.type
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.standard
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.kind
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.description
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          project.country?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          project.unit_of_measurement
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          includesSDG(search.toLowerCase(), project) ||
          includesDescription(search.toLowerCase(), project) ||
          includesTags(search.toLowerCase(), project) ||
          includesRegistry(search.toLowerCase(), project)
        ) {
          return project
        }
      })
    }

    if (registry?.length > 0) {
      let newArrayRegistry = []
      for (let i = 0; i < registry.length; i++) {
        let filterArrayRegistry = newArray?.filter(
          (el) =>
            el.registry_depositing_name?.toLowerCase() ==
            registry[i].toLowerCase()
        )
        newArrayRegistry = [...newArrayRegistry, ...filterArrayRegistry]
      }
      newArray = newArrayRegistry
    }

    if (name !== '' && name !== null) {
      newArray = newArray?.filter((project) => {
        if (project.name.toLowerCase().includes(name.toLowerCase())) {
          return project
        }
      })
    }

    // Filter by project kind
    if (kindFilter?.length > 0) {
      let newArrayKind = []
      for (let i = 0; i < kindFilter.length; i++) {
        let filterArrayKind = newArray?.filter(
          (el) => el.kind == kindFilter[i].toLowerCase()
        )
        newArrayKind = [...newArrayKind, ...filterArrayKind]
      }
      newArray = newArrayKind
    }

    // filter by year
    if (yearSince && yearUntil) {
      const date = new Date().getFullYear()

      const newArrayYear = newArray?.filter((project) => {
        if (project.is_vintage && project.vintage_items.length == 0 && withoutStock) {
          return project
        }
        if (project.is_vintage) {
          for (let i = 0; i < project.vintage_items.length; i++) {
            if (
              project.vintage_items[i].year >= yearSince &&
              project.vintage_items[i].year <= yearUntil
            ) {
              return project
            }
          }
        } else {
          if (date >= yearSince && date <= yearUntil) {
            return project
          }
        }
      })
      newArray = newArrayYear.slice()
    }
    // filter by price
    if (price[0] && price[1]) {
      newArray = newArray?.filter((project) => {
        if (project.is_vintage && project.vintage_items.length == 0 && withoutStock) {
          return project
        }
        if (project.is_vintage) {
          return (
            (
              project.vintage_items[0]?.final_unit_price
                ?.sell_base_amount +
              project.vintage_items[0]?.final_unit_price
                ?.fee_amount
            ).toFixed(2) >= price[0] &&
            (
              project.vintage_items[0]?.final_unit_price
                ?.sell_base_amount +
              project.vintage_items[0]?.final_unit_price
                ?.fee_amount
            ).toFixed(2) <= price[1]
          )
        } else {
          return (
            (
              project.final_unit_price?.sell_base_amount +
              project.final_unit_price?.fee_amount
            ).toFixed(2) >= price[0] &&
            (
              project.final_unit_price?.sell_base_amount +
              project.final_unit_price?.fee_amount
            ).toFixed(2) <= price[1]
          )
        }
      })
    }
    // filter by stock
    if (stock?.[0] !== null && stock?.[1]) {
      newArray = newArray?.filter((project) => {
        if (project.is_vintage && project.vintage_items.length == 0 && withoutStock) {
          return project
        }
        if (project.is_free_donation) {
          return project
        } else {
          if (project.is_vintage) {
            let stockBalance = 0
            project.vintage_items.forEach(
              (vint) => (stockBalance += vint.stock_balance)
            )
            if (stockBalance >= stock[0]) {
              let minPurchase =
                project.kgco2_minimum_purchase < 1000
                  ? 1
                  : project.kgco2_minimum_purchase / 1000
              if (minPurchase <= stock[1]) {
                return project
              }
            }
          } else {
            if (project.available_volume_credits >= stock[0]) {
              let minPurchase =
                project.kgco2_minimum_purchase < 1000
                  ? 1
                  : project.kgco2_minimum_purchase / 1000
              if (minPurchase <= stock[1]) {
                return project
              }
            }
          }
        }
      })
    }


    // filter by minimun purchase
    if (!minimumPurchase) {
      newArray = newArray?.filter(
        (project) => project.kgco2_minimum_purchase <= 1
      )
    }

    // filter by energy type
    if (kindFilter?.includes('energy') && selectedEnergyType.length > 0) {
      let newArrayEnergy = []
      for (let i = 0; i < selectedEnergyType.length; i++) {
        let filterArrayEnergy = newArray?.filter(
          (el) => el.energy_type == selectedEnergyType[i]
        )
        newArrayEnergy = [...newArrayEnergy, ...filterArrayEnergy]
      }
      newArray = newArrayEnergy
    }

    // filter by compensation type
    if (
      kindFilter?.includes('compensation') &&
      selectedCompensationType.length > 0
    ) {
      let newArrayCompensation = []
      for (let i = 0; i < selectedCompensationType.length; i++) {
        let filterArrayEnergy = newArray?.filter(
          (el) => el.type == selectedCompensationType[i]
        )
        newArrayCompensation = [
          ...newArrayCompensation,
          ...filterArrayEnergy
        ]
      }
      newArray = newArrayCompensation
    }

    // filter by type
    if (selectedTypes?.length > 0) {
      let newArrayTypes = []
      for (let i = 0; i < selectedTypes.length; i++) {
        let filterArrayTypes = newArray?.filter(
          (el) =>
            el.type == selectedTypes[i].value ||
            el.energy_type == selectedTypes[i].value
        )
        newArrayTypes = [...newArrayTypes, ...filterArrayTypes]
      }
      newArray = newArrayTypes
    }

    // filter by standard type
    if (selectedStandardType?.length > 0) {
      let newArrayStandards = []
      for (let i = 0; i < selectedStandardType.length; i++) {
        let filterArrayStandard = newArray?.filter(
          (el) => el.standard == selectedStandardType[i]
        )
        newArrayStandards = [
          ...newArrayStandards,
          ...filterArrayStandard
        ]
      }
      newArray = newArrayStandards
    }

    // filter by project tags
    if (selectedProjectTags?.length > 0) {
      let newArrayTags = []
      let filterArrayTags = []
      for (let i = 0; i < selectedProjectTags.length; i++) {
        filterArrayTags = newArray?.filter((el) => {
          if (el.tags.length > 0) {
            let existTag = false
            el.tags.forEach((tag) => {
              if (selectedProjectTags[i] == tag.id) {
                existTag = true
              }
            })
            if (existTag && !newArrayTags.includes(el)) {
              return el
            }
          }
        })
        newArrayTags = [...newArrayTags, ...filterArrayTags]
      }
      newArray = newArrayTags
    }

    // filter by country
    if (countries.length > 0) {
      let newArrayCountry = []
      for (let i = 0; i < countries.length; i++) {
        let filterArrayCountry = newArray?.filter(
          (el) => el.country?.id == countries[i].id
        )
        newArrayCountry = [...newArrayCountry, ...filterArrayCountry]
      }
      newArray = newArrayCountry
    }

    // filter by mechanism
    if (mechanismSelected?.length > 0) {
      let newArrayMechanism = []
      for (let i = 0; i < mechanismSelected.length; i++) {
        let filterArrayMechanism = newArray?.filter(
          (el) => el.mechanism == mechanismSelected[i]
        )
        newArrayMechanism = [
          ...newArrayMechanism,
          ...filterArrayMechanism
        ]
      }
      newArray = newArrayMechanism
    }

    // filter by impact
    if (selectedImpacts?.length > 0) {
      let newArrayImpacts = []
      for (let j = 0; j < selectedImpacts.length; j++) {
        newArray.forEach((project) => {
          const isIn = project.impacts.find(
            (el) => el.name == selectedImpacts[j]
          )
          if (isIn) {
            newArrayImpacts.push(project)
          }
        })
      }
      const removeDuplicatesArray = newArrayImpacts.filter(
        (item, index) => newArrayImpacts.indexOf(item) === index
      )
      newArray = removeDuplicatesArray
    }

    // filter by SDG
    if (SDGsRedux.length > 0) {
      let newArraySDG = []
      for (let j = 0; j < SDGsRedux.length; j++) {
        newArray.forEach((project) => {
          project.sdg_compliances.forEach((sdg) => {
            if (sdg.id === SDGsRedux[j]) {
              newArraySDG.push(project)
            }
          })
        })
        newArray = newArraySDG.slice()
        newArraySDG = []
      }
    }
    // filter by show without stock
    if (!withoutStock) {
      newArray = newArray?.filter(
        (project) => project.available_volume_credits > 0
      )
    }

    dispatch(setFilteredProjects(orderArray(newArray)))
  }


  function storePathValues(path: string) {
    const storage = sessionStorage
    if (!storage) return
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath')

    if (!storage.getItem('prevPath')) {
      storage.setItem('prevPath', null)
    }

    if (`${path}` !== prevPath) {
      storage.setItem('prevPath', prevPath)
    }

    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', `/results${path}`)
  }

  useEffect(() => localStorage.setItem('prevRoute', `${router.asPath}`), [
    router
  ])

  // get StandarTypes,EnergyType and CompensationType
  const getFilteringOptions = (arrayOfProjects: Array<any>) => {
    const arrayOfStandards = []
    const arrayOfEnergy = []
    const arrayOfCompensation = []
    const arrayOfRegistry = []
    getImpacts(arrayOfProjects)
    getMechanism(arrayOfProjects)

    arrayOfProjects?.forEach((project: any) => {
      const standard = project.standard
      const energy = project.energy_type
      const compensationType = project.type
      const registry = project.registry_depositing_name
      if (
        !arrayOfEnergy.includes(energy) &&
        energy !== null &&
        energy !== ''
      ) {
        arrayOfEnergy.push(energy)
      }
      if (
        !arrayOfRegistry.includes(registry) &&
        registry !== null &&
        registry !== ''
      ) {
        arrayOfRegistry.push(registry)
      }
      if (
        !arrayOfStandards.includes(standard) &&
        standard !== null &&
        standard !== ''
      ) {
        arrayOfStandards.push(standard)
      }
      if (
        !arrayOfCompensation.includes(compensationType) &&
        compensationType !== null &&
        compensationType !== ''
      ) {
        arrayOfCompensation.push(compensationType)
      }
    })
    dispatch(setRegistryTypes(arrayOfRegistry))
    dispatch(setStandardTypes(arrayOfStandards))
    dispatch(setEnergyTypes(arrayOfEnergy))
    dispatch(setCompensationTypes(arrayOfCompensation))
  }

  const handleChangeSort = (value) => {
    // dispatch(setReloadProjects(true));
    dispatch(setOrder(value))
    dispatch(setRefreshFilters(!refreshFilters))
  }

  useEffect(() => {
    if (filtersLoaded) {
      const history = sessionStorage.getItem('history')
        ? JSON.parse(sessionStorage.getItem('history'))
        : []

      if (!history.includes(router.asPath)) {
        const newHistory = []

        history.forEach((route) => {
          if (!newHistory.includes(route)) {
            newHistory.push(route)
          }
        })

        newHistory.push(router.asPath)

        sessionStorage.setItem('history', JSON.stringify(newHistory))
      }
    }

    window.history.pushState(
      window.location.pathname,
      'Title',
      `/results/${i18n.language}${router.asPath}`
    )
  }, [router.asPath, filtersLoaded])

  // infinite scrolling
  useEffect(() => {
    setProjectList([]) // reset projectList when filters change

    // se obtienen todos los id de las cards didYou que se van a mostrar y se desordena la lista de forma random
    const newDidYouKnowCards = shuffle(
      Array.from(Array(didYouKnowCardsQuantity), (_, index) => index + 1)
    ).slice(0, didYouKnowCardsQuantityToShow)

    setTotalProjects(filteredProjects?.length)
    if (!reloadProjects && filteredProjects) {
      const itemsPerPage = 18
      const startIndex = 0
      const endIndex = itemsPerPage
      const newList = [...filteredProjects.slice(startIndex, endIndex)]

      setProjectList(addCardDidYouKnowThat(newList, newDidYouKnowCards))

      setPage(1)
    }

    setMobileProjectList(filteredProjects?.slice(0, 4))
  }, [filteredProjects, refreshFilters])

  const handleLoadCards = () => {
    if (!reloadProjects && filteredProjects) {
      const itemsPerPage = 18
      const startIndex =
        page * itemsPerPage -
        (didYouKnowCardsQuantityToShow - didYouKnowCards.length)
      const endIndex = startIndex + itemsPerPage

      let newList = [...filteredProjects.slice(startIndex, endIndex)]

      const uniqueValues = {}
      newList = newList.filter(function (project) {
        if (!uniqueValues[project.id]) {
          uniqueValues[project.id] = true
          return true
        }
        return false
      })

      const filteredList = newList.filter((project) => {
        return !projectList.some((item) => item.id === project.id)
      })

      setProjectList(
        addCardDidYouKnowThat([...projectList, ...filteredList])
      )

      setPage(page + 1)
    }
  }

  const addCardDidYouKnowThat = (list, didCards?) => {
    const newProjectList = [...list]
    const newCardList = didCards ? [...didCards] : [...didYouKnowCards]
    const newCardSaved = []

    let count = didYouKnowCardsQuantityToShow

    for (let i = 1; i < didYouKnowCardsQuantityToShow + 1; i++) {
      if (
        newProjectList.length >= i * 8 &&
        newCardList.length === count
      ) {
        const completeRows = Math.floor(newProjectList.length / 18)
        const remaining =
          18 - (newProjectList.length - completeRows * 18)

        remaining === 18 && newCardSaved.push(newProjectList.pop())

        newProjectList.splice(i * 8 - 1, 0, newCardList.pop())
      }
      count--
    }

    newCardSaved.reverse()
    setDidYouKnowCards(newCardList)

    return newProjectList
  }


  const handleResize = () => {
    setWidth(window.innerWidth)
    setShowTabletFilter(false)
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  useEffect(() => {
    const handleScroll = () => {
      if (width < 1366 && showTabletFilter) {
        const columnBottom = filterColumnRef?.current?.getBoundingClientRect()
          .bottom
        const screenBottom = window.scrollY + window.innerHeight
        const buttomBottom =
          buttonApplyFilterRef?.current?.getBoundingClientRect()
            .bottom +
          window.scrollY +
          30
        const limit = columnBottom + window.scrollY

        if (!stopButtonApplyFilter && buttomBottom > limit) {
          buttonApplyFilterRef.current.style.position = 'unset'
          setStopButtonApplyFilter(true)
        } else if (
          stopButtonApplyFilter &&
          screenBottom <= buttomBottom
        ) {
          buttonApplyFilterRef.current.style.position = 'fixed'
          setStopButtonApplyFilter(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showTabletFilter, stopButtonApplyFilter])

  const handleRemainingCards = () => {
    let col = showTabletFilter || (width < 980 && width > 694) ? 2 : 3

    const totalCards =
      didYouKnowCardsQuantityToShow -
      didYouKnowCards.length +
      totalProjects

    const completeRows = Math.floor(totalCards / col)
    const remaining = col - (totalCards - completeRows * col)

    setRemainingCards(remaining === col || inMobile ? 0 : remaining)
    return
  }

  useEffect(() => {
    if (width > 694) {
      handleRemainingCards()
      setInMobile(false)
      setShowMore(true)
    } else if (width <= 694 && !inMobile) {
      setInMobile(true)
      setShowMore(false)
    }
  }, [width, filteredProjects, showTabletFilter, didYouKnowCards])

  const renderEmptySpace = () => {
    const spaces = [] // crea un array para almacenar los elementos
    for (let i = 0; i < remainingCards; i++) {
      spaces.push(
        <Col key={i}>
          <div
            style={{
              width: '288px',
              height: '312px',
              borderRadius: '8px',
              background: '#F5F5F5'
            }}
          />
        </Col>
      ) // agrega un elemento al array en cada iteración
    }
    return <>{spaces}</>
  }

  return (
    <div data-testid='index-page-test' style={{ width: '100%' }}>
      <React.Fragment>
        <>
          <Head>
            <title>{t('seo.ogTitle')}</title>
            <link rel='shortcut icon' type='image/x-icon' href='/results/images/favicon.ico' sizes='any' />
            <link
              rel='alternate'
              hrefLang={i18n.language}
              href={`https://market.climatetrade.com/results${i18n.language !== 'en' ? '/' + i18n.language : '/'}`}
            />
            <link rel='alternate' hrefLang='x-default' href='https://market.climatetrade.com/results' />
            <meta name='viewport' content='initial-scale=1.0, width=device-width' />
            <meta name='google-site-verification' content='caXvb9QYJOiyPr_cV3j8_2AR0wBrOrr6Zkwb9T24GWs' />
            <meta name='description' content={t('seo.description')} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={t('seo.ogTitle')} />
            <meta property='og:description' content={t('seo.description')} />
            <meta property='og:image' content='https://climatetrade.com/wp-content/uploads/2021/04/climatetrade-paisaje-parallax.jpg' />
            <meta property='og:url' content='https://market.climatetrade.com/' />
            <meta name='theme-color' content='#003E6F' />
            {/* Se utiliza en todos los entornos menos produccion para que no se indexen todos los entornos */}
            {/* <meta name="robots" content="noindex,nofollow" /> */}
          </Head>

          <Row justify='center'>
            <Col span={24}>
              <Background />
              <Search />
            </Col>
          </Row>
          {!inMobile && (
            <div className={styles['container']}>
              <Row justify='center' className={styles['content']}>
                <PopularFilters queryFilters={queryFilters} mobile={inMobile} />
              </Row>
            </div>
          )}
          <div className={styles['container']}>
            <Row justify='center' className={styles['content']}>
              <Col>
                <Row wrap={false} justify='space-between' className={styles['mobile-container']}>
                  {/* filtros desktop */}
                  {width >= 1366 && (
                    <Col className={styles['filter-section']}>
                      {projects ? (
                        <>
                          <Filters changeSort={handleChangeSort} />
                          <WhatsNewBanner banner='FOOTPRINT' />
                          <WhatsNewBanner banner='RFP' />
                        </>
                      ) : (
                        <SkeletonFilters />
                      )}
                    </Col>
                  )}

                  {/* grid */}
                  <Col className={styles['grid-column']}>
                    {/* texto cantidad de proyectos, order y movile filter */}
                    <Row>
                      {projects && !reloadProjects ? (
                        <Col>
                          <Row>
                            <Col span={24} className={styles['filter-section-mobile']}>
                              <ModalFilter changeSort={handleChangeSort} />
                              {inMobile && (
                                <PopularFilters queryFilters={queryFilters} mobile={inMobile} />
                              )}
                            </Col>
                          </Row>

                          <Row justify='space-between' className={styles['title-container']} wrap={width < 980}>
                            {/* filtros tablet */}
                            {width < 1366 && width >= 980 && (
                              <Col className={styles['filter-section']}>
                                <div className={styles['iconFilterMobile']}>
                                  <img
                                    src={menuOutlined.src}
                                    className={styles['menuOutlined']}
                                    onClick={() => setShowTabletFilter(!showTabletFilter)}
                                    alt='menu-icon'
                                  />
                                  {showTabletFilter ? ' Close filters' : ' Filters'}
                                </div>
                              </Col>
                            )}

                            <h3 className={styles['title']}>
                              {!reloadProjects && (filteredProjects?.length || projects?.length || portfolioList?.length || 0)} {t('titles.projects')}
                            </h3>

                            <div className={styles['select-recommended-container']}>
                              <span>{t('filters.sort')} :</span>
                              <Select
                                defaultValue={order}
                                bordered={false}
                                value={order}
                                className={styles['select-recommended']}
                                onChange={handleChangeSort}
                                options={[
                                  { value: '-scoring', label: t('filters.recommended') },
                                  { value: '-price', label: t('filters.majorPrice') },
                                  { value: 'price', label: t('filters.minorPrice') },
                                  { value: 'name', label: 'A - Z' },
                                  { value: '-name', label: 'Z - A' }
                                ]}
                              />
                            </div>
                          </Row>

                          {/* lista de cards */}
                          <Row wrap={false} justify={showTabletFilter ? 'space-between' : 'center'}>
                            {/* filtros tablet */}
                            {width < 1366 && (
                              <Col ref={filterColumnRef} className={styles['filter-section']} hidden={!showTabletFilter}>
                                {projects ? (
                                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div>
                                      <Filters changeSort={handleChangeSort} />
                                      <WhatsNewBanner banner='FOOTPRINT' />
                                      <br />
                                      <WhatsNewBanner banner='RFP' />
                                    </div>
                                    <Button ref={buttonApplyFilterRef} className={styles['btnApply']} onClick={() => setShowTabletFilter(false)}>
                                      Apply filters
                                    </Button>
                                  </div>
                                ) : (
                                  <SkeletonFilters />
                                )}
                              </Col>
                            )}
                            <Col
                              style={
                                width < 692
                                  ? { maxWidth: '360px', minWidth: '360px' }
                                  : showTabletFilter || width < 980
                                    ? { maxWidth: '640px', minWidth: '640px' }
                                    : { maxWidth: '960px', minWidth: '960px' }
                              }
                            >
                              {!reloadProjects && (
                                showMore ? (
                                  <InfiniteScroll
                                    dataLength={projectList?.length}
                                    next={handleLoadCards}
                                    hasMore={projectList?.length <
                                      didYouKnowCardsQuantityToShow - didYouKnowCards.length + totalProjects}
                                    loader={<SkeletonCategoryProjects />}
                                    scrollableTarget='scrollableDiv'
                                    className={filteredProjects?.length < 3 ? styles['infinite-container-2'] : styles['infinite-container']}
                                  >
                                    <Row gutter={[32, 32]} className={styles['mobile-list']} style={width < 692 ? { maxWidth: '360px', minWidth: '360px' } : showTabletFilter || width < 980 ? { maxWidth: '640px', minWidth: '640px' } : { maxWidth: '960px', minWidth: '960px' }}>
                                      {portfolioList?.map(portfolio => (
                                        <CardPortfolio key={portfolio.slug} portfolio={portfolio} />
                                      ))}
                                      {projectList?.map((project, index) =>
                                        typeof project !== 'number' ? (
                                          <Col key={index}>
                                            <CardProjects project={project} currencyList={currencies} />
                                          </Col>
                                        ) : (
                                          <Col className={styles['fade-in']} hidden={inMobile} key={index}>
                                            <CardDidYouKnowThat cardNumber={project} />
                                          </Col>
                                        )
                                      )}
                                      {remainingCards > 0 &&
                                        !(projectList?.length < totalProjects) && renderEmptySpace()}
                                    </Row>
                                  </InfiniteScroll>
                                ) : (
                                  <Row className={styles['mobile-list-show']}>
                                    {portfolioList?.map(portfolio => (
                                      <CardPortfolio key={portfolio.slug} portfolio={portfolio} />
                                    ))}
                                    {mobileProjectList?.map((project, index) => (
                                      <Col key={index}>
                                        <CardProjects project={project} currencyList={currencies} />
                                      </Col>
                                    ))}
                                    <button className={styles['showMoreButton']} onClick={() => setShowMore(true)}>
                                      {t('categ.showMore')}
                                    </button>
                                  </Row>
                                )
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Row>
                          <Col span={24}>
                            <div>
                              <div className={styles['containerSkeletonDesktop']}>
                                <SkeletonPreFiltersMobile />
                                <SkeletonCategoryProjects />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row justify='center' className={styles['container']}>
            <Col>{projects && <CategoriesPreFilter />}</Col>
          </Row>

          <UploadProject />
        </>
      </React.Fragment>
      <ExitIntentPopup />
    </div>
  )

}

export async function getStaticProps({ locale }: any) {
  return {
    props: { ...(await serverSideTranslations(locale, ['common'])) }
  }
}

export default memo(HomePage)

/* eslint-disable @next/next/no-img-element */
import React from 'react'
import { Checkbox } from 'antd'
import { useTranslation } from 'next-i18next'
import { useAppSelector, useAppDispatch } from 'src/app/hooks'
import {
  setProjectKind,
  setRefreshFilters,
} from 'src/features/filters/filtersSlice'
import { setFiltersKeys } from 'src/features/ui/uiSlice'
import styles from './ProjectKind.module.scss'

import carbonCircle from '../../../../assets/img/carbonCircle.png'
import contributionCircle from '../../../../assets/img/contributionCircle.png'
import renewableEnergyCircle from '../../../../assets/img/renewableEnergyCircle.png'
import biodiversityCircle from '../../../../assets/img/biodiversityCircle.png'

const KINDS = {
  Portfolio: { label: 'card.portfolio', image: carbonCircle.src },
  Contribution: { label: 'card.nature', image: contributionCircle.src },
  Energy: { label: 'card.renewable', image: renewableEnergyCircle.src },
  Compensation: { label: 'card.carbon', image: carbonCircle.src },
  Biodiversity: { label: 'card.biodiversity', image: biodiversityCircle.src },
  Carbon_forward: { label: 'Carbon forward', image: carbonCircle.src,
    style: {
      border: 'solid 8px #959ea5',
      width: 0,
      borderRadius: '100%',
    } }
}

const ProjectKind: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const categories = useAppSelector((state) => state.compensation.categories)
  const kindSelected = useAppSelector((state) => state.filters.projectKind)
  const refreshFilters = useAppSelector((state) => state.filters.refreshFilters)

  const onChange = (kind: string) => {
    const isIn = kindSelected?.find((el) => el === kind)
    if (isIn) {
      const newFilter = kindSelected.filter((el) => el !== kind)
      dispatch(setProjectKind(newFilter))
    } else {
      dispatch(setProjectKind([...kindSelected, kind]))
    }
    dispatch(setFiltersKeys(['projectKind']))
    dispatch(setRefreshFilters(!refreshFilters))
  }
  return (
    <div className={styles['kind-container']} data-testid='project-kind-filter'>
      {categories?.map((kind, index) => {
        const { label, image, style } = KINDS[kind]
        const isSelected = kindSelected?.includes(kind)

        return (
          <Checkbox
            className={isSelected ? styles['kind-text-selected'] : styles['kind-text']}
            checked={isSelected}
            onClick={() => onChange(kind)}
            key={index}
          >
            <span className={styles['text-checkbox']}>
              <img
                src={image}
                alt='color'
                style={style}
                className={styles['image-circle']}
              />
              {t(label)}
            </span>
          </Checkbox>
        )
      })}
    </div>
  )
}

export default ProjectKind
